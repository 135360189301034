import "./styles.scss"

import React from "react"

import Button from "components/Button"

const Content = () => {
  return (
    <section className="not-found-content">
      <div className="container-fluid">
        <p>
          Ta strona nie istnieje. Wybierz z menu podstronę do której chcesz
          dotrzeć lub kliknij przycisk poniżej aby wrócić na stronę główną.
        </p>
        <Button to="/">powrót do strony głównej</Button>
      </div>
    </section>
  )
}

export default Content
