import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/not-found"

const NotFoundPage = () => (
  <Layout
    seo={{
      title: "404",
      description:
        "Inwestycje w apartamenty premium. Zarabiaj inwestując w apartamenty inwestycyjne, hotelowe, komercyjne.",
    }}
  >
    <Breadcrumbs title="404" />
    <PageHeader title="Strona nie została znaleziona" />
    <Content />
  </Layout>
)

export default NotFoundPage
